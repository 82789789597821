import React, { Fragment } from 'react';

import Layout from '../components/layout';
import SignUpForm from '../components/SignUp';

const SignUpPage = () => (
  <div>
    <h1 style={{ color: 'white' }}>Реєстрація</h1>
    <SignUpForm />
  </div>
);

export default () => (
  <Layout>
    <SignUpPage />
  </Layout>
);
